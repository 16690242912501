import { useEffect, useState } from "react";
import { Playlist, Screen } from "../../../types";
import LoadingContainer from "../LoadingConitainer";
import AssetPlayer from "./AssetPlayer";
import LayoutPlayer from "./LayoutPlayer";
import {
  FetchedPlaylistType,
  PlaylistItem,
} from "../../../types/playlistTypes";
import isPlaylistItemActive from "../../utils/isPlaylistItemActive";
import { groupBy } from "../../utils";
import { AnimatePresence, motion } from "framer-motion";

export const luxonDateFormat = "yyyy-LL-dd, HH:mm";

export default function PlaylistPlayer({
  playlist,
  screen,
}: {
  playlist?: Playlist;
  screen: Screen;
}) {
  const [fetchedPlaylist, setFetchedPlaylist] =
    useState<FetchedPlaylistType | null>(null);

  const [currentFrame, setCurrentFrame] = useState<PlaylistItem | null>(null);

  useEffect(() => {
    if (!playlist?.id) return;

    const _options: RequestInit = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "playlist-id": playlist.id,
      },
    };

    fetch(import.meta.env.VITE_SERVER_URL! + "/getPlaylistInfo", _options).then(
      async (res) => {
        if (res.status === 200) {
          const data = await res.json();
          setFetchedPlaylist(data);
          initializePlaylist(data.items);
        }
      },
    );
  }, [playlist?.id]);

  async function initializePlaylist(playlistItems: PlaylistItem[]) {
    const _grouped = Object.values(groupBy(playlistItems, "playOrder"));

    if (playlistItems.length === 0) return;

    let _frameIdx = 0;
    // @ts-ignore
    window.frameIdx = _frameIdx;
    do {
      const _framePool = _grouped[_frameIdx];
      const _randomIndex = Math.floor(Math.random() * _framePool.length);
      const _newPlaylistItem = _framePool[_randomIndex];

      if (isPlaylistItemActive(_newPlaylistItem)) {
        setCurrentFrame(_newPlaylistItem);
        await new Promise((resolve) =>
          setTimeout(resolve, _newPlaylistItem.duration * 1000),
        );
      }

      if (_frameIdx === _grouped.length - 1) {
        _frameIdx = 0;
      } else {
        _frameIdx++;
      }
      // @ts-ignore
      window.frameIdx = _frameIdx;
      // eslint-disable-next-line no-constant-condition
    } while (true);
  }

  if (!fetchedPlaylist) return <LoadingContainer />;

  if (!currentFrame) return <>No Frames Active</>;

  function getTransitionVariant(_idx: number) {
    try {
      if (!fetchedPlaylist?.transition) return undefined;

      const _variants =
        typeof fetchedPlaylist.transition === "string"
          ? JSON.parse(fetchedPlaylist.transition)
          : fetchedPlaylist.transition;

      // @ts-expect-error - frameIdx might not be in window
      const _frameIdx = window.frameIdx;

      if (!_frameIdx) return undefined;

      const key = _frameIdx === 0 ? "0-1" : `${_frameIdx - 1}-${_frameIdx}`;

      // const key = "";
      if (key in _variants) {
        return _variants[key];
      }
      return undefined;
    } catch (_e) {
      return;
    }
  }

  return (
    <AnimatePresence initial={false}>
      <motion.div
        className="flex h-full w-full items-center justify-center overflow-clip"
        key={currentFrame.id}
        initial="initial"
        variants={getTransitionVariant(0)}
        animate="animate"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        }}
      >
        {currentFrame.type === "asset" ? (
          <AssetPlayer
            screen={screen}
            asset={fetchedPlaylist.assetsInfo.find(
              (v) => v.id === currentFrame.id,
            )}
          />
        ) : (
          <LayoutPlayer
            key={`layout_${currentFrame.id}`}
            layoutInfo={fetchedPlaylist.layoutsInfo.find(
              (v) => v.id === currentFrame.id,
            )}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
}
