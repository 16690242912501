import { FullScreenHandle } from "./use-full-screen-handle";
import React from "react";

export interface FullScreenProps extends React.ComponentPropsWithoutRef<'div'> {
}

const fullScreenContext = React.createContext<FullScreenHandle['node'] | null>(null);

const FullScreenProviderContext = fullScreenContext;

export const FullScreenProvider: React.FC<FullScreenProps> = ({
  children,
  className,
}) => {
  const node = React.useRef<HTMLDivElement>(null);

  return (
    <div
      className={className}
      ref={node}
    >
      <FullScreenProviderContext.Provider value={node}>
        {children}
      </FullScreenProviderContext.Provider>
    </div>
  );
};

export const useFullScreenNode = () => {
  const node = React.useContext(FullScreenProviderContext);
  if (!node) {
    throw new Error('useFullScreenNode must be used within a FullScreenProvider');
  }
  return node;
}
