import React from "react";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function CustomButton(props?: ButtonProps) {
  return (
    <button
      aria-hidden="true"
      {...props}
      className={`rounded-md border-2 border-black bg-white px-8 py-3 text-2xl text-black transition-all focus:scale-125 focus:bg-black focus:text-white focus:outline-none ${props?.className}`}
    >
      {props?.children}
    </button>
  );
}
