import reloadPage from "../utils/AndroidBridge";
import CustomButton from "./CustomButton";

export function ResetAppButton({ id }: { id: string }) {
  return (
    <CustomButton
      id={id}
      className="w-44 border-none text-sm"
      onClick={() => {
        localStorage.removeItem("deviceId");
        localStorage.removeItem("orgId");
        reloadPage();
      }}
    >
      Reset App
    </CustomButton>
  );
}
