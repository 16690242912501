import { useEffect, useRef } from "react";
import { useLogs } from "../../hooks/useLogs";

export default function LogsContainer() {
  const bottomRef = useRef<HTMLDivElement>(null);

  const { logs, showLogs } = useLogs();

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [logs]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  if (!showLogs) return <></>;
  return (
    <div className="absolute bottom-0 right-0 flex max-h-screen w-96 flex-col rounded-md bg-black/50 p-2 text-white">
      <button onClick={() => { throw new Error("for testing purposes") }}>Throw error</button>
      <p className="pb-2 font-medium">Logs:</p>

      <div className="h-full flex-grow overflow-auto">
        {logs.map((event, idx) => (
          <div
            key={idx.toString()}
            className="w-full break-words border-b border-gray-500 py-2"
          >
            {event}
          </div>
        ))}
        <div ref={bottomRef} className="list-bottom"></div>
      </div>
    </div>
  );
}
