import { ScreenInfoProvider, useScreenInfo } from "../hooks/useScreen";
import LayoutPlayer from "./Players/LayoutPlayer";
import {
  ScreenSocketInfoProvider,
  useScreenSocketInfo,
} from "../hooks/useScreenSocket";
import { useCallback, useEffect, useRef, useState } from "react";
import AssetPlayer from "./Players/AssetPlayer";
import PlaylistPlayer from "./Players/PlaylistPlayer";
import { CustomScreen } from "../hooks/useScreen/types";
import isInsideIframe from "../utils/isInsideIframe";
import AnimatingGradientBackground from "./ui/AnimatingGradientBackground";
import { useLogs } from "../hooks/useLogs";
import { useAuth } from "../hooks/useAuth";
import { MusicController } from "./ui/MusicController";
import { cn } from "../lib/utils";
import { useFullScreen } from "../hooks/useFullScreen";

type Props = {
  initialScreenInfo: CustomScreen | null;
};
const OrganizationScreen = (props: Props) => {
  return (
    <ScreenInfoProvider {...props}>
      <OrganizationScreenPresenter />
    </ScreenInfoProvider>
  );
};

function OrganizationScreenPresenter() {
  const handle = useFullScreen();
  const { onLayoutChanged, onScreenChanged, onPlaylistChanged, onMusicChanged } =
    useScreenInfo();
  const { screenInfo } = useAuth()

  const [showFullScreenButton, setShowFullScreenButton] = useState(false);
  const timerRef = useRef<number | null>(null);

  // Helper function to hide the button after 3 seconds
  const hideButton = useCallback(() => {
    setShowFullScreenButton(false);
  }, []);

  // Track when mouse enters and leaves
  const handleMouseEnter = () => {
    setShowFullScreenButton(true);
    // Clear any existing timeout to prevent it from hiding the button prematurely
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleMouseLeave = () => {
    // Set a new timeout to hide the button after 3 seconds
    timerRef.current = window.setTimeout(hideButton, 3000);
  };

  useEffect(() => {
    return () => {
      // Clear any ongoing timeout when the component unmounts
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const { musicInfos } = useScreenInfo()
  const audioRef = useRef<HTMLAudioElement | null>()

  return (
    <div
      className="h-screen w-screen bg-white"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {handle.shouldShowFullScreenButton && (
        <>
          <button
            onClick={handle.enter}
            className={`text absolute right-8 top-8 z-10 rounded-md bg-slate-800 p-2 text-white transition-all ${!showFullScreenButton ? "opacity-0" : ""
              }`}
          >
            <FullScreenButton />
          </button>
        </>
      )}
      <MusicController
        musicInfos={musicInfos}
        key={JSON.stringify(musicInfos)}
        className={cn("absolute top-8 z-10 left-1/2", {
          "opacity-0": !showFullScreenButton || navigator.userAgent.includes("TV") || isInsideIframe(),
        })}
        onPlay={(track) => {
          if (!audioRef.current) {
            audioRef.current = new Audio(track.url)
          }
          audioRef.current.play()
        }}
        onPause={() => {
          audioRef.current?.pause()
        }}
        onVolumeChange={(track, volume) => {
          if (!audioRef.current) {
            audioRef.current = new Audio(track.url)
          }
          audioRef.current!.volume = volume
        }}
        onTrackChange={(track) => {
          if (!track) {
            return audioRef.current?.pause()
          }
          audioRef.current?.pause()
          audioRef.current = new Audio(track.url)
          audioRef.current.pause()
          audioRef.current.play()
        }}
        onNext={(track) => {
          audioRef.current?.pause()
          audioRef.current = new Audio(track.url)
          audioRef.current.pause()
          audioRef.current.play()
        }}
        onPrev={(track) => {
          audioRef.current?.pause()
          audioRef.current = new Audio(track.url)
          audioRef.current.pause()
          audioRef.current.play()
        }}
      />
      <div
        aria-hidden="true"
        className="flex h-screen w-screen flex-col items-center justify-center gap-4 bg-white text-black"
      >
        {screenInfo && (
          <ScreenSocketInfoProvider
            onMusicChanged={onMusicChanged}
            onLayoutChanged={onLayoutChanged}
            onScreenChanged={onScreenChanged}
            onPlaylistChanged={onPlaylistChanged}
            key={screenInfo.id}
          >
            <OrganizationScreenPlayer screenInfo={screenInfo} />
          </ScreenSocketInfoProvider>
        )}
      </div>
    </div>
  );
}
function OrganizationScreenPlayer({
  screenInfo,
}: {
  screenInfo?: CustomScreen | null;
}) {
  const { isOnline } = useScreenSocketInfo();
  const { showLogs } = useLogs();

  return (
    <div className="h-full w-full">
      {showLogs && (
        <div className="absolute left-2 top-2 z-10 flex items-center justify-center gap-2 rounded-full border bg-white px-4 py-1 text-sm">
          <div
            className={`h-3 w-3 rounded-full ${isOnline ? "bg-green-500" : "bg-red-500"
              }`}
          ></div>
          {isOnline ? "Online" : "Offline"}
        </div>
      )}
      {screenInfo?.playlist ? (
        <PlaylistPlayer playlist={screenInfo.playlist} screen={screenInfo} />
      ) : screenInfo?.layout ? (
        <LayoutPlayer layoutInfo={screenInfo.layout} />
      ) : screenInfo?.asset ? (
        <AssetPlayer asset={screenInfo.asset} screen={screenInfo} />
      ) : (
        <div
          className={`relative z-0 flex h-full w-full flex-col items-center justify-center gap-8 overflow-hidden text-[#3d2b24] font-exo`}
          aria-hidden="true"
        >
          <h1 aria-hidden="true" className="text-[calc(4px+7vw)] font-semibold">
            Hi there 👋
          </h1>
          <h2 aria-hidden="true" className="text-center text-[calc(4px+3vw)]">
            Waiting for you to select a design from <br /> your account to
            display here...
          </h2>
          <AnimatingGradientBackground className="absolute -z-10 h-full w-full opacity-50" />
        </div>
      )}
    </div>
  );
}

const FullScreenButton = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3H9V5H6.46173L11.3047 9.84298L9.8905 11.2572L5 6.3667V9H3V3Z"
        fill="currentColor"
      />
      <path
        d="M3 21H9V19H6.3764L11.3046 14.0718L9.89038 12.6576L5 17.548V15H3V21Z"
        fill="currentColor"
      />
      <path
        d="M15 21H21V15H19V17.5244L14.1332 12.6576L12.719 14.0718L17.6472 19H15V21Z"
        fill="currentColor"
      />
      <path
        d="M21 3H15V5H17.5619L12.7189 9.84301L14.1331 11.2572L19 6.39032V9H21V3Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default OrganizationScreen;
