import { createContext, useContext, useState } from "react";
import { CustomScreen, ScreenInfoReturnProps } from "./types";
import { useAuth } from "../useAuth";
import { MusicInfo } from "../../../types/music";

const screenInfoContext = createContext<ScreenInfoReturnProps | null>(null);
const { Provider } = screenInfoContext;
type Props = {
  children: React.ReactNode;
  initialScreenInfo: CustomScreen | null;
};
export function ScreenInfoProvider({ children, initialScreenInfo }: Props) {
  const [screenInfo, _setScreenInfo] = useState<CustomScreen | null>(initialScreenInfo);

  const [musicInfos, setMusicInfos] = useState<MusicInfo[]>([]);

  const { storeOrgId, storeDeviceId, getScreenInfo } = useAuth();

  function onScreenChanged(data: any) {
    if (data?.new?.deleted) {
      storeOrgId(undefined);
      storeDeviceId(undefined);
      return;
    }
    getScreenInfo(true)
  }

  function onLayoutChanged(_data: any) {
    getScreenInfo(true)
  }

  function onPlaylistChanged(_data: any) {
    getScreenInfo(true)
  }

  async function onMusicChanged(musics: MusicInfo[] | undefined = []) {
    // Object.is is always false since we are calling the setter
    // stringified versions are compared to infinite rerender
    if (JSON.stringify(musics) !== JSON.stringify(musicInfos))
      setMusicInfos(musics)
  }

  return (
    <Provider
      value={{
        screenInfo,
        onScreenChanged,
        onLayoutChanged,
        onPlaylistChanged,
        onMusicChanged,
        musicInfos,
      }}
    >
      {children}
    </Provider>
  );
}

export const useScreenInfo: () => ScreenInfoReturnProps = () => {
  const context = useContext(screenInfoContext);
  if (!context) throw "useScreens must be used within a ScreensProvider";
  return context;
};
