import { Asset, Screen } from "../../../types";
import generateAssetUrl from "../../utils/generateAssetUrl";

export default function AssetPlayer({
  asset,
  screen,
}: {
  asset?: Asset;
  screen: Screen;
}) {
  return (
    <img
      className="h-full w-full"
      style={{
        // @ts-expect-error - image_fit is a string
        objectFit: screen.image_fit ?? "none",
      }}
      src={generateAssetUrl(asset?.id, "original")}
    ></img>
  );
}
