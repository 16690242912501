const generateAssetUrl = (id?: string, assetSize?: "small" | "original") => {
  return (
    "" +
    import.meta.env.VITE_ASSETS_URL +
    import.meta.env.VITE_CLOUDFLARE_ACCOUNT_HASH +
    "/" +
    (id ?? placeHolderImageId) +
    (assetSize == "small" ? "/width=200" : "/public")
  );
};

export const placeHolderImageId = "682b63d0-423b-4faa-2b1a-44e9d417a800";

export default generateAssetUrl;
