import { useEffect, useState } from "react";
import { Layout } from "../../../types";
import { useLogs } from "../../hooks/useLogs";

interface LayoutPlayerProps {
  layoutInfo?: Layout;
}

interface Font {
  family: string;
  url: string;
}

const LayoutPlayer = ({ layoutInfo }: LayoutPlayerProps) => {
  const [zoom, setZoom] = useState(1);

  const { setLogs } = useLogs();

  function fetchFonts() {
    const fontsApiUrl = (import.meta.env.VITE_SERVER_URL ?? "") + "/getFonts";

    fetch(fontsApiUrl).then((response) => {
      const html = layoutInfo?.html ?? "";
      const css = layoutInfo?.css ?? "";

      response.json().then((fonts: Font[]) => {
        const _prevFragment = document.getElementById("style_div")

        if (_prevFragment) document.parentNode?.removeChild(_prevFragment)

        const fragment = document.createElement("div");
        fragment.id = "style_div"

        for (const font of fonts) {
          if (html.includes(font.family) || css.includes(font.family)) {
            const link = document.createElement("link");
            link.href = font.url;
            link.rel = "stylesheet";
            link.onload = () => {
              setLogs((prev) => [...prev, "fontLoaded " + font.family]);
            };
            fragment.appendChild(link);
          }
        }

        document.head.appendChild(fragment);
      });
    });
  }

  useEffect(() => {
    if (layoutInfo) {
      const availableWidth = Math.min(
        screen.availWidth,
        Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0,
        ),
      );

      const availableHeight = Math.min(
        screen.availHeight,
        Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0,
        ),
      );

      const tailwind = document.createElement("script");
      tailwind.id = "tailwind_script";
      tailwind.src = "https://cdn.tailwindcss.com";

      const _prevScript = document.getElementById("tailwind_script");

      if (!_prevScript) {
        document.head.appendChild(tailwind);
      } else {
        onTailwindLoaded();
      }

      tailwind.onload = () => {
        onTailwindLoaded();
      };

      if (layoutInfo.height && layoutInfo.width) {
        /// Now set zoom scale

        if (
          layoutInfo.width > availableWidth ||
          layoutInfo.height > availableHeight
        ) {
          setLogs((prev) => [
            ...prev,
            "layoutInfo.width , layoutInfo.height" +
            layoutInfo.width +
            " x " +
            layoutInfo.height,
          ]);
          setLogs((prev) => [
            ...prev,
            "availableWidth , availableHeight" +
            availableWidth +
            " x " +
            availableHeight,
          ]);

          const vertScale = availableHeight / layoutInfo.height;
          const horzScale = availableWidth / layoutInfo.width;

          setLogs((prev) => [...prev, "vertScale " + vertScale]);
          setLogs((prev) => [...prev, "horzScale " + horzScale]);

          setZoom(Math.min(vertScale, horzScale));
        }
      }

      fetchFonts();

      setTimeout(() => {
        const layoutDiv = document.getElementById("layoutIframe");
        if (layoutDiv) {
          setLogs((prev) => [...prev, "layoutDivLoaded" + layoutDiv.innerHTML]);
        }
      }, 10000);
    }
  }, [layoutInfo]);

  function onTailwindLoaded() {
    if (!layoutInfo) return;

    setLogs((prev) => [...prev, "tailwindLoaded"]);
    const style = document.createElement("style");
    style.id = `layout_style`;
    style.innerHTML = layoutInfo.css ?? "";

    const _prevStyle = document.getElementById("layout_style");

    if (_prevStyle) {
      document.head.removeChild(_prevStyle);
    }
    document.head.appendChild(style);

    const layoutDiv = document.getElementById("layoutIframe");
    if (layoutDiv) {
      let htmlToRender = layoutInfo.html ?? "";
      let scripts: any = [];
      try {
        const pattern = /<script>([\s\S]*?)<\/script>/gi;
        scripts = htmlToRender.match(pattern);
        htmlToRender = htmlToRender.replace(pattern, "");

        htmlToRender = htmlToRender.replace("<body", "<div");
        htmlToRender = htmlToRender.replace("</body>", "</div>");

        setLogs((prev) => [...prev, "htmlToRender success"]);
      } catch (e) {
        setLogs((prev) => [...prev, "error " + e]);
      }

      layoutDiv.innerHTML = htmlToRender;
      const child = layoutDiv.children[0];
      if (child) {
        child.setAttribute(
          "style",
          `height: ${layoutInfo.height}px;width: ${layoutInfo.width}px`,
        );
      }

      const _prevFragment = document.getElementById("script_fragment")
      if (_prevFragment) document.parentElement?.removeChild(_prevFragment)

      const fragment = document.createElement("div");
      fragment.id = "script_fragment"

      if (scripts) {
        for (let i = 0; i < scripts.length; i++) {
          const script = scripts[i];
          const scriptContent = script
            .replace(`<script>`, ``)
            .replace(`</script>`, ``);
          const scriptEl = document.createElement("script");
          scriptEl.textContent =
            `(function script${i}() {` + scriptContent + " }())";
          fragment.appendChild(scriptEl);
        }
      }

      document.head.appendChild(fragment);
    }
  }

  return (
    <div
      id="backgroundElem"
      className="flex h-screen w-screen flex-col items-center justify-center bg-white"
    >
      <div
        className="bg-white"
        style={{
          // zoom: zoom,
          transform: `scale(${zoom})`,
          transformOrigin: "center",
          width: layoutInfo?.width + "px",
          height: layoutInfo?.height + "px",
          position: "relative",
        }}
      >
        <div id="layoutIframe" className="text-3xl">Loading...</div>
      </div>
    </div>
  );
};
export default LayoutPlayer;
