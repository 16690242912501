const reloadPage = () => {
  try {
    const uuid = localStorage.getItem("uuid");
    if (uuid) {
      localStorage.setItem("previous_uuid", uuid);
      localStorage.removeItem("uuid");
    }
    //@ts-ignore
    AndroidJSBridge.reloadPage();
  } catch (e) {
    console.log("No AndroidJSBridge");
  }
  //reload page
  window.location.reload();
};

export const quitAndroidApp = () => {
  try {
    //@ts-ignore
    AndroidJSBridge.quitApp();
  } catch (e) {
    console.log("No AndroidJSBridge");
  }
};

export const isAndroid = (): boolean => {
  try {
    // @ts-ignore
    if (AndroidJSBridge) {
      return true;
    }
  } catch (e) {
    return false;
  }

  return false;
};

export default reloadPage;
