import { createContext, useContext, useEffect, useState } from "react";
import DataFetchStatus from "../../../types/DataFetchStatus";
import {
  gzLog,
  gzLogProps,
  initializeAnalyticsWithDeviceId,
} from "../../utils/gzAnalytics";
import { CustomScreen } from "../useScreen/types";
import { useLogs } from "../useLogs";
import reloadPage from "../../utils/AndroidBridge";

const useAuthContext = createContext<AuthReturnProps | null>(null);
const { Provider } = useAuthContext;
type Props = {
  children: React.ReactNode;
};
export function AuthProvider({ children }: Props) {
  const [orgId, setOrgId] = useState<string | null>(null);
  const [deviceId, setDeviceId] = useState<string | null>(null);

  const [screenInfoFetchStatus, setScreenInfoFetchStatus] =
    useState<DataFetchStatus>("idle");

  const [screenInfo, setScreenInfo] = useState<CustomScreen | null>(null);

  const serverUrl = import.meta.env.VITE_SERVER_URL ?? "";

  const { setLogs } = useLogs();

  function log(props: gzLogProps) {
    setLogs((e) => [...e, JSON.stringify(props)]);
    gzLog(props);
  }

  function storeOrgId(id?: string) {
    if (id) {
      localStorage.setItem("orgId", id);
    } else {
      localStorage.removeItem("orgId");
    }
    setOrgId(id || null)
    reloadPage()
  }

  function storeDeviceId(id?: string) {
    if (!id) {
      localStorage.removeItem("deviceId");
    } else {
      localStorage.setItem("deviceId", id);
    }
    setDeviceId(id || null)
  }

  async function refreshAccessToken(): Promise<string | null> {
    const _options: RequestInit = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      log({
        name: "TokenRefreshed",
        eventtype: "APICall",
        payload: {
          deviceId: deviceId,
          orgId: orgId,
        },
      });
      const data = await fetch(`${serverUrl}/refreshToken`, _options).then(
        (res) => res.json(),
      );

      log({
        name: "TokenRefreshed",
        eventtype: "APISuccess",
        payload: {
          deviceId: deviceId,
          orgId: orgId,
        },
      });
      return data.accessToken;
    } catch (e) {
      log({
        name: "TokenRefreshed",
        eventtype: "APIFailure",
        payload: {
          deviceId: deviceId,
          orgId: orgId,
          error: e,
        },
      });
      return null;
    }
  }

  async function getScreenInfo(softReload = false) {
    if (screenInfoFetchStatus === "fetching") return;

    try {
      if (!softReload)
        setScreenInfoFetchStatus("fetching");
      const _options: RequestInit = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: import.meta.env.VITE_IS_DEV || isPreviewMode
          ? JSON.stringify({
            deviceId: localStorage.getItem("deviceId"),
            orgId: localStorage.getItem("orgId"),
            isPreview: isPreviewMode,
          })
          : undefined,
      };

      log({
        name: "ScreenAndLayoutInfo",
        eventtype: "APICall",
        payload: {
          orgId: orgId,
        },
      });
      fetch(
        import.meta.env.VITE_SERVER_URL! + "/screenAndLayoutInfo",
        _options,
      ).then(async (res) => {
        if (res.status === 200) {
          const data = await res.json();
          const orgId = data.orgId;
          log({
            name: "ScreenAndLayoutInfo",
            eventtype: "APISuccess",
            payload: {
              orgId: orgId,
              response: JSON.stringify(data),
            },
          });
          setDeviceId(data.deviceId);
          setOrgId(orgId);
          setScreenInfo(data.screen);
          setScreenInfoFetchStatus("success");
        } else if (res.status === 409) {
          if (deviceId !== null) {
            storeOrgId(undefined);
            throw new Error("No screen found");
          } else {
            setScreenInfoFetchStatus("success");
          }
        } else if (res.status === 401) {
          if (orgId) {
            const token = await refreshAccessToken();

            if (token) {
              getScreenInfo();
              return;
            } else {
              storeOrgId(undefined);
              throw new Error("No screen found");
            }
          } else {
            setOrgId(null);
          }
          setScreenInfoFetchStatus("success");
        } else {
          setScreenInfoFetchStatus("error");
        }
      });
    } catch (_e) {
      log({
        name: "ScreenAndLayoutInfo",
        eventtype: "APIFailure",
        payload: {
          orgId: orgId,
        },
      });
      setScreenInfoFetchStatus("error");
    }
  }

  useEffect(() => {
    function getOrgId() {
      const _id = localStorage.getItem("orgId");
      const _deviceId = localStorage.getItem("deviceId");
      setDeviceId(_deviceId);
      setOrgId(_id);

      initializeAnalyticsWithDeviceId(_deviceId);

      getScreenInfo();
    }

    getOrgId();
  }, []);

  const loginViaDeviceId = async (uid: string, organizationId: string) => {
    const _options: RequestInit = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        signUpToken: import.meta.env.VITE_SIGN_UP_TOKEN ?? "",
        uid: uid,
      }),
    };

    log({
      name: "LoggedIn",
      eventtype: "APICall",
      payload: {
        orgId: organizationId,
        uid: uid,
      },
    });

    await fetch(`${serverUrl}/login`, _options)
      .then((res) => res.json())
      .then((res) => {
        log({
          name: "LoggedIn",
          eventtype: "APISuccess",
          payload: {
            deviceId: res.uid,
            orgId: organizationId,
          },
        });
        storeDeviceId(res.uid);
        storeOrgId(organizationId);
      })
      .catch((e) => {
        log({
          name: "LoggedIn",
          eventtype: "APIFailure",
          payload: {
            deviceId: deviceId,
            orgId: organizationId,
            error: e,
          },
        });
      });
  };

  const hasDefaultOrgAndDeviceId = !!import.meta.env.VITE_DEFAULT_ORG_ID && !!import.meta.env.VITE_DEFAULT_DEVICE_ID;
  const [isPreviewMode, setIsPreviewMode] = useState(hasDefaultOrgAndDeviceId);

  useEffect(() => {
    const isPreviewMode = localStorage.getItem("isPreviewMode");
    if (isPreviewMode !== null) {
      setIsPreviewMode(isPreviewMode === "true");
    }
  }, []);

  const storeIsPreviewMode = (isPreviewMode: boolean) => {
    localStorage.setItem("isPreviewMode", isPreviewMode.toString());
    setIsPreviewMode(isPreviewMode);
  };

  return (
    <Provider
      value={{
        orgId,
        deviceId,
        storeOrgId,
        storeDeviceId,
        refreshAccessToken,
        loginViaDeviceId,
        screenInfo,
        screenInfoFetchStatus,
        getScreenInfo,
        isPreviewMode,
        storeIsPreviewMode,
      }}
    >
      {children}
    </Provider>
  );
}

export const useAuth: () => AuthReturnProps = () => {
  const context = useContext(useAuthContext);
  if (!context) throw "useAuth must be used within a useAuthProvider";
  return context;
};

interface AuthReturnProps {
  orgId: string | null;
  deviceId: string | null;
  storeOrgId: (id?: string) => void;
  storeDeviceId: (id?: string) => void;
  refreshAccessToken: () => Promise<string | null>;
  loginViaDeviceId: (uid: string, organizationId: string) => void;
  screenInfoFetchStatus: DataFetchStatus;
  screenInfo: CustomScreen | null;
  getScreenInfo: (softReload: boolean) => void;
  isPreviewMode: boolean;
  storeIsPreviewMode: (isPreviewMode: boolean) => void;
}
