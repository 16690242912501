import { PairingProvider, usePairing } from "../hooks/usePairing";
import LoadingContainer from "./LoadingConitainer";
import PairingPinContainer from "./PairingPinContainer";

const PairingScreen = () => {
  return (
    <PairingProvider>
      <PairingScreenPresenter />
    </PairingProvider>
  );
};

function PairingScreenPresenter() {
  const { randomPin } = usePairing();
  return (
    <div className="h-screen w-screen bg-primary">
      {randomPin ? (
        <PairingPinContainer
          key={`pin_screen_${randomPin}`}
          randomPin={randomPin}
        />
      ) : (
        <LoadingContainer />
      )}
    </div>
  );
}

export default PairingScreen;
