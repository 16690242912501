import React, { useEffect, useState } from "react";
import isInsideIframe from "../utils/isInsideIframe";
import reloadPage, { isAndroid, quitAndroidApp } from "../utils/AndroidBridge";
import CustomButton from "./CustomButton";
import { ResetAppButton } from "./ResetAppButton";

export default function ExitPopupDialog({
  children,
  hasError,
}: {
  children: React.ReactNode;
  hasError?: boolean;
}) {
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    const rootEl = document.getElementById("rootElement");
    if (rootEl) {
      rootEl.focus();
    }

    document.addEventListener("keyup", function (e) {
      const popup = document.getElementById("popup");

      if (!popup && hasError) {
        switch (e.code) {
          case "ArrowDown":
            const retyHasFocus = document.activeElement?.id === "retry";
            if (retyHasFocus) {
              document.getElementById("resetApp")?.focus();
            } else {
              document.getElementById("retry")?.focus();
            }
            break;
          case "ArrowUp":
            const resetAppHasFocus = document.activeElement?.id === "resetApp";
            if (resetAppHasFocus) {
              document.getElementById("retry")?.focus();
            } else {
              document.getElementById("resetApp")?.focus();
            }
            break;
          case "Escape":
            setShowDialog(true);
            document.getElementById("no_btn")?.focus();

            break;
          default:
            break;
        }

        return;
      }
      switch (e.code) {
        case "ArrowDown":
        case "ArrowUp":
          const yesOrNoHasFocus =
            document.activeElement?.id === "yes_btn" ||
            document.activeElement?.id === "no_btn";
          const resetAppHasFocus =
            document.activeElement?.id === "resetAppDialog";

          if (yesOrNoHasFocus) {
            document.getElementById("resetAppDialog")?.focus();
          } else if (resetAppHasFocus) {
            document.getElementById("no_btn")?.focus();
          } else {
            document.getElementById("no_btn")?.focus();
          }

          break;
        case "ArrowLeft":
          document.getElementById("yes_btn")?.focus();
          break;
        case "ArrowRight":
          document.getElementById("no_btn")?.focus();
          break;
        default:
          break;
      }
    });
  }, [hasError]);

  useEffect(() => {
    if (showDialog) {
      // const exitAppTitle = document.getElementById("popupTitle");
      // if (exitAppTitle) {
      //   exitAppTitle.focus();
      // }
      document.getElementById("no_btn")?.focus();
    } else {
      const rootEl = document.getElementById("rootElement");
      if (rootEl) {
        rootEl.focus();
      }
    }
  }, [showDialog]);

  return (
    <>
      <div className="absolute inset-0 m-auto">
        {!hasError ? (
          children
        ) : (
          <div
            className="flex h-full min-h-screen w-full flex-col items-center justify-center gap-8"
            style={{
              pointerEvents: showDialog ? "none" : "auto",
            }}
          >
            <p className="text-4xl">Something went wrong!</p>

            <CustomButton
              className="w-44"
              id="retry"
              onClick={() => {
                reloadPage();
              }}
            >
              Retry
            </CustomButton>
            <ResetAppButton id="resetApp" />
          </div>
        )}
      </div>

      {showDialog && (
        <div
          id="popup"
          aria-hidden="true"
          className="absolute flex h-screen w-screen items-center justify-center bg-black bg-opacity-50"
        >
          <div
            aria-hidden="true"
            className="flex flex-col items-center justify-center gap-4 rounded-2xl bg-white px-12 py-12 text-black md:px-[5vw] md:py-[5vh]"
          >
            <p
              aria-hidden="true"
              tabIndex={-1}
              className="text-2xl font-semibold focus:outline-none md:text-[2vw]"
            >
              Are you sure you want to exit the app?
            </p>
            <div
              aria-hidden="true"
              className="flex items-center justify-center gap-10 pt-6 text-2xl md:text-[1.7vw]"
            >
              <CustomButton
                id="yes_btn"
                aria-hidden="true"
                tabIndex={-1}
                onClick={() => {
                  setShowDialog(false);
                  if (isAndroid()) {
                    // handle android exit
                    quitAndroidApp();
                  } else {
                    if (isInsideIframe()) {
                      window.parent.postMessage("exit_app", "*");
                    }
                  }
                }}
              >
                Yes
              </CustomButton>
              <CustomButton
                id="no_btn"
                aria-hidden="true"
                tabIndex={-1}
                onClick={() => {
                  setShowDialog(false);
                }}
              >
                No
              </CustomButton>
            </div>
            <ResetAppButton id="resetAppDialog" />
          </div>
        </div>
      )}
    </>
  );
}

